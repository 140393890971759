<template>
  <div class="ui-add-button">
      <div class="add-button">
        <!-- 友達追加の準備ができたらこっちを表示 -->
        <!-- <img alt="QRコード" class="qr" src="https://qr-official.line.me/gs/M_177zuyrf_BW.png?oat__id=1583346"> -->
        <!-- <a data-v-4185e1d6="" href="https://lin.ee/Yp66vzJ" target="_blank" rel="noopener noreferrer"><img data-v-4185e1d6="" src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" border="0" class="line"></a> -->

        <!-- 準備中の対応 -->
        <div data-v-4185e1d6="" href="https://lin.ee/Yp66vzJ" target="_blank" rel="noopener noreferrer">
          <img data-v-4185e1d6="" src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" border="0" class="line">
        </div>
        <p class="message">※現在、準備中です</p>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ui-add-button",
  components: {},
  props: {
    // path: String,
    // text: String,
    // type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
  .add-button{
    padding-top: 20px;
    width: 30%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    // justify-content: space-between; // 友達追加の準備ができたらこっち
     @media screen and (min-width:768px) and ( max-width:1024px){
      width: 50%;
     }
      @media screen and (max-width: 767px) {
  width: 80%;
  }
    .qr{
      width: 100px;
    }
    .line{
      width: 200px;
      vertical-align: bottom;
      @media screen and (max-width: 767px){
        width: 150px;
      }
    &:hover{
      // opacity: 0.7; // 友達追加の準備ができたらこっち
      cursor: not-allowed;
    }
    }
    .message {
      width: 100%;
      font-weight: bold;
    }
  }
</style>